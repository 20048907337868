body{
    background: radial-gradient(white, white, white);
}

.card{
    width: 30rem;
    height: 40rem;
}

.card:hover{
    box-shadow: 10px 20px 30px 10px rgba(0,0,0,0.253)!important;
}

.card-body{
    padding: 3rem 0!important;
    justify-content: center;
}
.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
    justify-content: center; 
}
.card-title{
    font-size: 3rem; 
}

.container-fluid .row{
    padding-top: .5rem;
}

.overflow{
    overflow: hidden;
}

.card-img-top{
    transform: scale(1);
    transition: transform 0.5s ease;
}

.card-img-top:hover{
    transform: scale(1.05);
}